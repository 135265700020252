*,
html,
body {
  margin: 0;
  padding: 0;
}
body {
  background-color: white;
  

  font-family: Arial;

  
}
#root {
  margin: 0px;
  padding: 0px;
}
/* my main colors */
:root {
  --color1: rgb(224, 117, 87);
  --color2: #ff7f50;
  --color3: #00ffff;
  --color4: #86e3c5;
}

.hero {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
}
.hero_section {
  background-image: url("../src/images/header-bg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  width: 100%;
  height: 100vh;
  opacity: 1;

  /* border-bottom-right-radius: 50% 100px; */
}

.hero_text {
  color: white;
  font-size: x-large;
  display: flex;
  flex-direction: column;

  padding: 120px 0;

  /* border-bottom-right-radius: 50% 100px; */
  border-bottom-left-radius: 50% 100px;
}
.hero_text h1 {
  color: coral;
  font-size: xxx-large;
  vertical-align: middle;
}
.hero_text span {
  padding: 10px 0;
}
.call-to-action {
  width: 100vw;
  display: flex;
  flex-flow: column;
  background-color: var(--color2);
  margin: 0px, 10px, 0px, 10px;
  padding: 2rem;

  font-size: 30px;
}
/* countdown clock */
.watch {
  display: flex;
  flex-flow: row;
  background-color: lightblue;
  margin: 0px, 10px, 0px, 10px;
  padding: 2rem;
  border: 2px solid aqua;
  height: 80%;
  font-size: 45px;
}

.navbrand {
  display: flex;
  align-items: row;
  margin-right: 100px;
}
.navbrand h1 {
  font-family: "Poppins", sans-serif;
  color: tomato;
}

@media (max-width: 768px) {
  .navdiv {
    flex-flow: column nowrap;
  }
}
.App {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  margin-top: 0;
  margin-right: 0px;
  color: #000;
}

.container {
  background-color: rgb(71, 160, 255);
  display: flex;
  align-items: center;

  /* flex-flow: column nowrap; */

  flex-flow: column wrap;
  min-width: 700px;
  width: 100%;
  overflow: visible;
}
@media (max-width: 768px) {
  .container {
    background-color: tomato;
    display: flex;
    margin-left: 1px;
    min-width: 200px;
    /* flex-flow: column nowrap; */

    flex-direction: column;
    justify-content: space-evenly;

    width: 100%;
    overflow: visible;
  }
}

.main {
  background-color: black;
  display: flex;
  align-content: center;
  flex-direction: row;
  width: 100vh;
  margin: 0 auto;
  /* margin-left: 35%; */
}

.complete {
  text-decoration: line-through;
}
.list_item {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  flex-direction: row;
  color: #000;
  border: solid bisque;
  border-radius: 2rem;
  margin-left: 40px;
  margin-right: 20px;
  margin-bottom: 20px;
  padding: 1px;
  margin-top: 10px;
}
.flatlist {
  list-style-type: none;

  font-size: 20px;
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 10px;
  padding-left: 1rem;
  margin-right: 5rem;
  justify-content: space-evenly;
}
input[type=button], input[type=submit], input[type=reset] {
  background-color: #04AA6D;
  border: none;
  color: white;
  padding: 5px 16px;
  text-decoration: none;
  margin: 4px 2px;
  cursor: pointer;
}
.refresh {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 400px;
  height: 250px;
  border-radius: 50%;

}
.start-button {
  position: absolute;
  top: calc(50% - 40px);
  left: calc(50% - 40px);
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: var(--color4);
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}
.refresh-button {
  position: absolute;
  top: 22px;
  left: 20%;
  transform: translateX(90%);

  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #e91e63;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flow-button {
  position: absolute;
  top: -120px;
  left: 10%;
;
  transform: translateX(-35%);
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #ffc107;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.random-button {
  position: absolute;
  top: 25px;
  left: 20%;
  transform: translateX(-160%);
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #4caf5e;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}
article {

  display: flex;
  align-items: left;
  justify-content: space-between;
  flex-direction: column;
}
iframe {
  display: block;

  margin-left: 70px;
  pointer-events: none;
}
article h1 {
  display: flex;
  align-items: left;
  margin: 10px;
  color: var(--color2);
  /* grid-column: 1/6;

  background: white; */
}

article p {
  text-align: left;
  text-indent: 0px; /* first line indent */

  line-height: 32px; /* within paragraph */
  margin-bottom: 30px; /* between paragraphs */
  margin-left: 10px;

  font-family: Georgia, "Times New Roman", Times, serif;
}
article h2 {
  display: flex;

  margin-left: 10px;
  margin-top: 20px;
  color: var(--color4);
}
h2,
p:not(:last-child) {
  margin-top: 2.4rem;
}
li {
  font-weight: bold;
  text-align: left;

  
  margin:40px;
  list-style-type: circle;
}
@media (max-width: 8in) {
  article {
    /* display: grid;
    grid-column: 3/4;
    grid-template-columns: 1fr; */
    display: flex;
    align-items: left;
    justify-content: space-between;
    flex-direction: column;
  }
}
.project_side {
  display: flex;
  flex-direction: row;
}
.tooltip-wrap {
  position: relative;
}
.tooltip-wrap .tooltip-content {
  display: none;

  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}
.tooltip-wrap:hover .tooltip-content {
  display: block;
}
.task-form {
  max-width: 1000px;
  margin: 0 auto;
  padding: 1rem;
  background-color: #f2f2f2;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-group {
  margin-bottom: 1rem;
}

label {
  display: block;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

input[type='text'],
input[type='date'],
textarea {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

textarea {
  height: 100px;
}

button {
  padding: 0.5rem 1rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

button:hover {
  background-color: #0056b3;
}
.form-container {
  margin-bottom: 20px;
}

.task-list-container {
  max-width: 1000px;
  padding-top: 20px;
}
.red-button {
  color: white;
  background-color: red;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
}
.twitter-icon-container {
  display: inline-block;
}

.twitter-icon {
  display: inline-block;
  width: 30px; /* Adjust size as needed */
  height: 30px; /* Adjust size as needed */
  background-image: url('../src/images/icons8-twitter.svg'); /* Replace 'path_to_twitter_icon.svg' with the path to your Twitter icon image */
  background-size: cover;
  vertical-align: middle;
  margin-right: 5px; /* Adjust spacing between icon and text */
}
