@tailwind base;
@tailwind components;
@tailwind utilities;


body {
    font-family: "Inter", sans-serif;
  }
  .sticky {
    /* @apply ud-fixed ud-z-[9999] ud-transition ud-bg-white ud-bg-opacity-80; */
    backdrop-filter: blur(5px);
    box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.1);
  }
  
  .sticky .header-logo {
    @apply ud-py-3 lg:ud-py-2;
  }
  
  .sticky .menu-scroll.active {
    @apply ud-text-primary;
  }
  
  .navbarTogglerActive > span:nth-child(1) {
    /* @apply ud-transform ud-rotate-45 ud-top-[7px]; */
  }
  .navbarTogglerActive > span:nth-child(2) {
    @apply ud-opacity-0;
  }
  .navbarTogglerActive > span:nth-child(3) {
    /* @apply ud-top-[-8px] ud-rotate-[135deg]; */
  }
  
  input#checkboxLabel:checked ~ .box span {
    @apply ud-opacity-100;
  }